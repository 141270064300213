const validation = () => {
  const containers = document.querySelectorAll(".form-block");

  if (containers.length === 0) {
    return;
  }

  containers.forEach(function(container) {
    const labels = container.querySelectorAll("label");
    const button = container.querySelector("button");

    labels.forEach((label) => {
      const span = label.querySelector(".label__span");
      const input = label.querySelector("input, textarea");
      const name = input.getAttribute("name");

      if (name === "name") {
        const inputNameHandler = () => {
          if (input.value !== "") {
            label.classList.remove("incorrect");
            label.classList.add("correct");
            span.textContent = "Ваше имя";
            span.classList.remove("is-hide");
          }

          if (input.value === "") {
            label.classList.remove("incorrect");
            label.classList.remove("correct");
            span.textContent = "";
            span.classList.add("is-hide");
          }

          if (input.value.length === 1) {
            span.textContent = "Введите Ваше имя";
            label.classList.remove("correct");
            label.classList.add("incorrect");
          }
        };

        inputNameHandler();

        input.addEventListener("input", inputNameHandler);
        input.addEventListener("invalid", inputNameHandler);
      }

      if (name === "tel") {
        const inputTelHandler = () => {
          if (input.value !== "") {
            label.classList.remove("correct");
            span.textContent = "Номер телефона";
            span.classList.remove("is-hide");

          }

          if (input.value.length === 2) {
            span.textContent = "";
            span.classList.add("is-hide");
          }

          if (input.value === "") {
            span.textContent = "";
            span.classList.add("is-hide");
          }

          if (input.value.length === 16 || input.value.length === 17) {
            label.classList.add("correct");
          }
        };

        inputTelHandler();

        input.addEventListener("input", inputTelHandler);
        input.addEventListener("invalid", inputTelHandler);
      }

      if (name === "message") {
        const inputTextHandler = () => {
          if (input.value !== "") {
            span.textContent = "Комментарий";
            span.classList.remove("is-hide");

          }

          if (input.value === "") {
            label.classList.remove("correct");
            span.textContent = "";
            span.classList.add("is-hide");
          }
        };

        inputTextHandler();

        input.addEventListener("input", inputTextHandler);
        input.addEventListener("invalid", inputTextHandler);
      }
    });

    button.addEventListener("click", (evt) => {
      const inputs = container.querySelectorAll("input, textarea");

      if (inputs[0].value === "" || inputs[0].value < 2) {
        evt.preventDefault();
        inputs[0].focus();
      } else if (inputs[1].value === "") {
        evt.preventDefault();
        inputs[1].focus();
      } else if (inputs[2].value === "") {
        evt.preventDefault();
        inputs[2].focus();
      }
    });
  });
};

export default validation;
