import nodeListForEach from './node-list-for-each';
import tel from './tel';
import animation from './animation';
import menuOpen from './menu-open';
import modal from './modal';
import introSlider from './intro-slider';
import headerScroll from './header';
import numeral from './numeral';
import accordion from './accordion';
import validation from './validation';

class App {
  static init() {
    nodeListForEach();
    tel();
    animation();
    menuOpen();
    modal();
    introSlider();
    headerScroll();
    numeral();
    accordion();
    validation();
  }
}


App.init();
window.App = App;
