const menuOpen = () => {
  const buttonMenu = document.querySelector(".header__menu-btn--mob");

  if (!buttonMenu) {
    return;
  }

  const menu = document.querySelector(".menu-mob");
  if (!menu) {
    return;
  }

  const scrollLock = window.scrollLock;
  const html = document.querySelector("html");
  const body = document.body;

  const closeMenu = () => {
    menu.classList.remove("is-hide");
    scrollLock.enablePageScroll(html);
    scrollLock.addScrollableTarget(html);
    scrollLock.enablePageScroll(body);
    scrollLock.addScrollableTarget(body);
    scrollLock.removeScrollableTarget(menu);
  };

  const openMenu = () => {
    menu.classList.add("is-hide");
    scrollLock.disablePageScroll(html);
    scrollLock.addLockableTarget(html);
    scrollLock.disablePageScroll(body);
    scrollLock.addLockableTarget(body);
    scrollLock.addScrollableTarget(menu);
  };

  buttonMenu.addEventListener("click", function() {
    if (buttonMenu.classList.contains("active")) {
      closeMenu();
      buttonMenu.classList.remove("active");
    } else {
      buttonMenu.classList.add("active");
      openMenu();
    }
  });

  $(document).on('mouseup', function(evt) {
    if (evt.target.closest(".menu-mob") === null && evt.target.closest(".header__menu-btn--mob") === null) {
      closeMenu();
      buttonMenu.classList.remove("active");
    }
  });

  const btnClose = document.querySelector(".menu__close");
  const header = document.querySelector(".header");

  btnClose.addEventListener("click", function() {
    closeMenu();
    buttonMenu.classList.remove("active");
  });

  const currentHeaderState = () => {
    if (header.classList.contains("is-scroll")) {
      btnClose.classList.add("show");
    } else if (btnClose.classList.contains("show")) {
      btnClose.classList.remove("show");
    }
  };
  currentHeaderState();

  window.addEventListener("scroll", function() {
    currentHeaderState();
  });
};

export default menuOpen;
