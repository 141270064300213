const headerScroll = () => {
  const $main = $("main");
  const main = document.querySelector("main");

  const $header = $(".header");
  const header = document.querySelector(".header");

  if ($header) {
    let headerHeigh = $(".header").outerHeight();

    // Header становится fixed, main добавляется отступ
    const scrollHeader = () => {
      const introTop = main.getBoundingClientRect().top;

      if (introTop === -100 || introTop < -100) {
        $header.addClass("transform");
        let a = headerHeigh + "px"
        $main.css("padding-top", a);
      } else {
        $header.removeClass("transform");
        $main.css("padding-top", "0");
      }

      const isVisible = introTop < -300;

      if (isVisible) {
        $header.addClass("is-scroll");
      } else if (header.classList.contains("is-scroll") && !isVisible) {
        $header.removeClass("is-scroll");
      }
    };

    $(window).on("scroll", scrollHeader);
    $(window).resize(function() {
      scrollHeader();
    });

    // Открытие и закрытие header-menu с помощью fade
    const $serviceLink = $(".menu__link");
    const $headerMenu = $(".header-menu");
    const $btn = $(".menu__link .header__menu-btn--desk");
    const keyCode = {
      ESC: 27,
    };

    const open = () => {
      $headerMenu.slideDown(300);
      $headerMenu.addClass("show");
      $btn.addClass("active");
    };

    const close = () => {
      $headerMenu.slideUp(300);
      $headerMenu.removeClass("show");
      $btn.removeClass("active");
      $serviceLink.blur();
    };

    $serviceLink.click(function(evt) {
      evt.preventDefault();
      if ($headerMenu.hasClass("show")) {
        close();
      } else {
        open();
      }
    });

    // Определение и установка координаты top для header-menu
    const resizeTopCoordinate = () => {
      let height = $(".header").outerHeight();
      let a = height + "px"
      $headerMenu.css("top", a);
    };
    resizeTopCoordinate();

    $(window).resize(function() {
      resizeTopCoordinate();
    });

    $serviceLink.on("mouseenter", function() {
      resizeTopCoordinate();
    });

    // Закрытие header-menu при scroll для переопределления координаты top
    $(window).on("scroll", function () {
      close();
      resizeTopCoordinate();
    });

    // Закрытие header-menu при нажатии вне меню
    $(document).on('mouseup', function(evt) {
      if (evt.target.closest(".header-menu") === null && evt.target.closest(".menu__link") === null) {
        close();
      }
    });

    // Закрытие header-menu по ESC
    $(document).on("keydown", function(evt) {
      if (evt.keyCode === keyCode.ESC && $headerMenu.hasClass("show")) {
        close();
      }
    });
  }

};

export default headerScroll;
