const introSlider = () => {
  const Swiper = window.Swiper;
  const i = Swiper.prototype.init;
  Swiper.prototype.init = function () {
    this.touchEventsData.formElements = '*';
    i.call(this);
  };

  $(document).ready(function(){
    //	$('.horizontalTab').jqTabs({direction: 'horizontal'});
      $('.mobile-menu-opener').click(function(){
        $('#mobile-menu').addClass('opened');
      });
      $('#mobile-menu .close').click(function(){
        $('#mobile-menu').removeClass('opened');
      });


    var swiper = new Swiper('.slider .swiper-container', {
          effect: 'coverflow',
          // grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
        });

    var swiper2 = new Swiper('.team .swiper-container', {
          effect: 'coverflow',
          // grabCursor: true,
          centeredSlides: true,
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
    navigation: {
        nextEl: '.team .swiper-button-next',
      },
        });
    var swiper3 = new Swiper('.works .swiper-container', {
          effect: 'coverflow',
          // grabCursor: true,
          centeredSlides: true,
          slidesPerView: 1,
          loop: true,
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
          breakpoints: {
            340: {
              slidesPerView: 3,
            },
            700: {
              slidesPerView: 4,
            },

          },
          navigation: {
            nextEl: '.works .swiper-button-next',
            prevEl: '.works .swiper-button-prev',
          },
        });


    });

};

export default introSlider;
