const tel = () => {
  const formBlocks = document.querySelectorAll(".form-block");

  if (!formBlocks.length) {
    return;
  }

  formBlocks.forEach((formBlock) => {
    const input = formBlock.querySelector("#input-tel");

    if (input) {
      const phoneMask = IMask( input, {
        mask: "+{7} 000 000-00-00"
      });
    }

  });
};

export default tel;
